// From:
//  https://betterprogramming.pub/the-best-practice-with-google-place-autocomplete-api-on-react-939211e8b4ce
//
import { h } from 'preact';
import {useEffect, useRef} from "preact/hooks";
import {GOOGLE_MAPS_API_KEY} from '../../consts/consts'
import {loadScript} from '../../utils/script'
import style from './search-location-input.style.css';

let autoComplete;

function handleScriptLoad(updateQuery, autoCompleteRef) {
  console.log('handleScriptLoad start:', autoCompleteRef)
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["(cities)"], componentRestrictions: { country: "au" } }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
  console.log('handleScriptLoad end')
}

async function handlePlaceSelect(updateQuery) {
  console.log('handlePlaceSelect start')
  const addressObject = autoComplete.getPlace();
  //const location = addressObject.address_components[0].long_name
  const query = addressObject.formatted_address;
  updateQuery(query);
  console.log(addressObject);
}

export const SearchLocationInput = (props) => {
  //const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    console.log('loadscript google maps!')
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
      () => handleScriptLoad(props.onChange, autoCompleteRef)
    )
  }, [])

  return (
    <div style={props.containerStyle}>
      <input
        ref={autoCompleteRef}
        onChange={event => props.onChange(event.target.value)}
        placeholder={props.placeholder}
        value={props.value}
        class={style.input}
      />
    </div>
  );
}

