import { h } from 'preact';
import {SearchBar} from './search-bar'

const App = () => (
	<div id='website-search-app' className={`${process.env.NODE_ENV}`}>
		<SearchBar/>
	</div>
)

export default App;
