import './style/index.css';
import App from './components/app';

//export default App;

import habitat from 'preact-habitat';
//import Widget from './components/widget';

function init() {
  let app = habitat(App)
  /**
   * option 1: render inline
  *
  app.render({
    inline: true,
    clean: false
  })*/

  /**
   * option 2: render in selector
  */
  app.render({
    selector: ".website-search-container",
    inline: false,
    clean: false
  });

  /**
   * option 3: render in cleinet specified
  */
  // niceLogin.render({
  //   clientSpecified: true
  //   inline: false,
  //   clean: false
  // });
}

// in development, set up HMR:
if (module.hot) {
  require('preact/devtools') // enables React DevTools, be careful on IE
  module.hot.accept('./components/app', () => requestAnimationFrame(init))
}

init()