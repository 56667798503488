import { h } from 'preact'
import {useEffect, useState} from "preact/hooks"
import {StyledPopper} from '../styled-popper';
import style from './select.style.css';

export const Select = (props) => {
    const {placeholder, options, onChange, value, containerStyle} = props
    const selected = options.find(o => o.value == value)
    return (
        <StyledPopper
            placeholder={selected ? selected.label : placeholder}
            popperClass={style.popper}
            containerStyle={containerStyle}
        >
            {(popperProps) => {
                return (
                    <>
                        {options.map(option => (
                            <div 
                                value={option.value} key={option.value} class={style.option}
                                onClick={(event) => {
                                    const e = {
                                        target: {
                                            value: option.value
                                        }
                                    }
                                    onChange(e)
                                    popperProps.setOpen(false)
                                }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </>
                )
            }}
        </StyledPopper>
    )
}
