import { h } from 'preact';
import {useEffect, useState} from "preact/hooks";
import style from './button.style.css';


import SearchIcon from '../../assets/magnifying-glass-solid.svg'

export const Button = (props) => {
    const {icon} = props
	return (
		<div class={style.button} onClick={props.onPress} style={props.containerStyle}>
			{/*icon && <img src={icon} class={style.icon} />*/}
			<SearchIcon class={style.icon}/>
            {props.label}
		</div>
	);
}
