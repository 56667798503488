import { h } from 'preact';
import {useState} from "preact/hooks";
import style from './search-bar.style.css';
import {Select} from '../select'
import { DatePicker } from '../date-picker';
import { Button } from '../button'
//import {searchIcon} from '../../assets'
import { SearchLocationInput } from '../search-location-input';
import {lightFormat} from 'date-fns'

const TEST_LOCAL = false
const PRODUCTION = true // use production else staging url

export const SearchBar = (props) => {
	const [type, setType] = useState('')
    const [date, setDate] = useState(['',''])
    const [location, setLocation] = useState('')
    const [capacity, setCapacity] = useState('')

    const onSearchPress = () => {
        const params = []
        if (date[0] && date[1]) {
            let dateStr = date.map(date => lightFormat(date, 'yyyy-MM-dd'))
            params.push(`date=${dateStr[0]},${dateStr[1]}`)
        }
        if (type) {
            params.push(`types=${type}`)
        }
        if (capacity) {
            params.push(`capacity=${capacity}`)
        }
        if (location) {
            params.push(`location=${location}`)
        }
        const query = params.join('&')
        const base = TEST_LOCAL ? 'http://localhost:3006/customer' : (PRODUCTION ? 'https://app.airplacer.com' : 'https://stg-customer.airplacer.com')
        const url = `${base}?${query}`
        window.open(url, '_blank')
    }

	return (
		<div class={style.searchBar}>
            <SearchLocationInput
                placeholder='Search by location' 
                onChange={(location) => {
                    setLocation(location)
                }}
                containerStyle={{flex: 180}}
                value={location}
            />
            <div class={style.inputDivider}/>
            <Select
                placeholder='All spaces'
                options={[
                    {label: 'Corporate', value: 'corporate'},
                    {label: 'Creative', value: 'creative'},
                    {label: 'Functions', value: 'functions'},
                    {label: 'Sports', value: 'sports'},
                    {label: 'Outdoor', value: 'outdoor'},
                    {label: 'Other', value: 'other'},
                ]}
                onChange={(event) => setType(event.target.value)}
                containerStyle={{flex: 110}}
                value={type}
            />
            <div class={style.inputDivider}/>
            <DatePicker 
                placeholder='Dates'
                selectsRange
                selected={date[0]}
                startDate={date[0]}
                endDate={date[1]}
                onChange={(value) => {
                    setDate(value)
                }}
                containerStyle={{flex: 150}}
            />
            <div class={style.inputDivider}/>
            <Select
                placeholder='People'
                options={[
                    {label: '0-20', value: '0,20'},
                    {label: '0-50', value: '0,50'},
                    {label: '0-100', value: '0,100'},
                    {label: '100-200', value: '100,200'},
                ]}
                onChange={(event) => setCapacity(event.target.value)}
                containerStyle={{flex: 100}}
                value={capacity}
            />
            <div class={style.searchDivider}/>
            <Button
                //icon={searchIcon}
                label='Search'
                onPress={onSearchPress}
                containerStyle={{flex: 1}}
            />
		</div>
	);
}
