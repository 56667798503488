
let scripts = {}

export const loadScript = (url, callback) => {
  try {
    if (scripts[url]) {
      console.log('script load already called, aborting')
      return
    }

    let script = document.createElement("script");
    script.type = "text/javascript";

    scripts[url] = script
  
    if (script.readyState) {
      script.onreadystatechange = function() {
        console.log('script.onreadystatechange')
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }
  
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  } catch (err) {
    console.error('loadScript error: ', err)
  }
}