import { h } from 'preact'
import {useEffect, useState, useRef} from "preact/hooks"
import { createPopper } from '@popperjs/core'

export const usePopper = (props) => {
    const referenceRef = useRef(null)
    const popperRef = useRef(null)
    const [popperInstance, setPopperInstance] = useState(null)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const inst = createPopper(referenceRef.current, popperRef.current, props)
        setPopperInstance(inst)
    }, [referenceRef.current])

    const setPopperOpen = (o = true) => {
        setOpen(o)
        popperInstance.update()
    }

    const togglePopperOpen = () => {
        setPopperOpen(!open)
    }

    return {open, setOpen: setPopperOpen, toggleOpen: togglePopperOpen, referenceRef, popperRef, popperInstance}
}

export const Popper = (props) => {
    const {reference, children, options} = props
    const {open, setOpen, toggleOpen, referenceRef, popperRef} = usePopper(options)
    return (
        <>
            {reference({ref: referenceRef, setOpen, toggleOpen})}
            {children({ref: popperRef, open, setOpen, toggleOpen})}
        </>
    )
}