import {Popper} from '../popper/popper'
import {useEffect} from 'preact/hooks'
import style from './styled-popper.style.css'
import ChevronDown from '../../assets/chevron-down.svg'

export const StyledPopper = (props) => {
    const {children, popperClass} = props
    return (
        <Popper
            reference={(popperProps) => {
                return (
                    <div ref={popperProps.ref} style={props.containerStyle} class={style.referenceContainer} onClick={popperProps.toggleOpen}>
                        <div>{props.placeholder}</div>
                        <ChevronDown class={style.chevron} />
                    </div>
                )
            }}
            options={{
                placement: 'bottom-start',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 0]
                        }
                    }
                ]
            }}
        >
            {(popperProps) => {
                const {open, setOpen, ...otherProps} = popperProps

                // add an event listener to close the popup when the user clicks outside
                useEffect(() => {
                    if (!open) {
                        return
                    }

                    const onClick = (evt) => {
                        setOpen(false)
                    }
                    document.addEventListener('click', onClick)

                    return () => {
                        document.removeEventListener('click', onClick)
                    }
                }, [open])
                
                return (
                    <div {...otherProps} style={{display: open ? 'block' : 'none'}} class={`${style.popper} ${popperClass}`}>
                        {children(popperProps)}
                    </div>
                )
            }}
        </Popper>
    )
}
