import { h } from 'preact'
import { forwardRef } from 'preact/compat'
import RDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { addMonths, subDays } from 'date-fns'
import style from './date-picker.style.css'
import ChevronDown from '../../assets/chevron-down.svg'

export const DatePicker = (props) => {
    const CustomInput = forwardRef(({ value, onClick }, ref) => {
        return (
            <div className={style.inputDiv} onClick={onClick} ref={ref}>
                <div>{value || props.placeholder}</div>
                <ChevronDown class={style.chevron} />
            </div>
        )
    })

    return (
        <div style={props.containerStyle}>
            <RDatePicker 
                placeholderText={props.placeholder}
                dateFormat="MMM d"
                className='ws-date-picker'
                includeDateIntervals={[
                    { start: subDays(new Date(), 1), end: addMonths(new Date(), 12) },
                ]}
                customInput={<CustomInput />}
                {...props}
            />
        </div>
    )
}